<script setup>
import IconFacebook from 'assets/images/facebook.svg'
import FooterSlogan from 'assets/images/fuer-en-guetae-stall.svg'
import IconInstagram from 'assets/images/instagram.svg'
import IconLinkedin from 'assets/images/linkedin.svg'
import IconTiktok from 'assets/images/tiktok.svg'
import IconWhatsapp from 'assets/images/whatsapp.svg'
import IconYoutube from 'assets/images/youtube.svg'

const {
	serviceNavigation,
	socialNavigation,
	legalNavigation,
	address,
	googleMapsLink,
	phoneOffice,
	phoneService,
	email,
	openingHours
} = storeToRefs(useSettingsStore())

const { trackEvent } = useFathom()

const footer = ref(null)
const footerHeight = useFooterHeight()
const { height } = useElementSize(footer)

watchEffect(() => {
	footerHeight.value = height
})
</script>

<template>
	<footer
		id="footer"
		ref="footer"
		class="footer grid"
	>
		<div class="wrapper">
			<section class="info">
				<address class="address">
					{{ address }}
					<NuxtLink
						v-if="googleMapsLink"
						:to="googleMapsLink.url"
						:target="googleMapsLink?.target"
						@click="trackEvent('Footer: Google Maps')"
					>
						Anfahrt Google Maps
					</NuxtLink>
					<div class="contact">
						<div
							v-if="phoneOffice"
							class="phone-office"
						>
							Büro <NuxtLink
								:href="'tel:' + phoneOffice"
								@click="trackEvent('Footer: Telefon Büro')"
							>
								{{ phoneOffice }}
							</NuxtLink>
						</div>
						<div
							v-if="phoneService"
							class="phone-service"
						>
							Service / Pikett <NuxtLink
								:href="'tel:' + phoneService"
								@click="trackEvent('Footer: Telefon Service')"
							>
								{{ phoneService }}
							</NuxtLink>
						</div>
						<div
							v-if="email"
							class="email"
						>
							<NuxtLink
								:href="'mailto:' + email"
								@click="trackEvent('Footer: E-Mail')"
							>
								{{ email }}
							</NuxtLink>
						</div>
					</div>
				</address>
				<div class="opening-hours">
					<span>Öffnungszeiten</span>
					{{ openingHours }}
				</div>
			</section>
			<nav>
				<ul
					v-if="serviceNavigation"
					class="service-menu"
				>
					<li
						v-for="({
							title,
							link,
							eventName,
							_uid
						}) in serviceNavigation"
						:key="_uid"
					>
						<NuxtLinkLocale
							:to="resolveLink(link)"
							:target="link?.target"
							class="more-link"
							@click="trackEvent(eventName)"
						>
							{{ title }}
						</NuxtLinkLocale>
					</li>
				</ul>
				<ul
					v-if="legalNavigation"
					class="legal-menu"
				>
					<li
						v-for="({
							title,
							link,
							eventName,
							_uid
						}) in legalNavigation"
						:key="_uid"
						class="legal-item"
					>
						<NuxtLinkLocale
							:to="resolveLink(link)"
							:target="link?.target"
							class="legal-link"
							@click="trackEvent(eventName)"
						>
							{{ title }}
						</NuxtLinkLocale>
					</li>
				</ul>
				<ul
					v-if="socialNavigation"
					class="socials-menu"
				>
					<li
						v-for="({
							title,
							link,
							eventName,
							_uid
						}) in socialNavigation"
						:key="_uid"
						class="social-item"
					>
						<NuxtLinkLocale
							:to="link.cached_url"
							:target="link.target"
							:aria-label="title"
							class="social-link"
							@click="trackEvent(eventName)"
						>
							<IconYoutube
								v-if="title === 'YouTube'"
								:stroke-width="1.5"
								color="var(--color-blue)"
							/>
							<IconLinkedin
								v-if="title === 'LinkedIn'"
								:stroke-width="1.5"
								color="var(--color-blue)"
							/>
							<IconFacebook
								v-if="title === 'Facebook'"
								:stroke-width="1.5"
								color="var(--color-blue)"
							/>
							<IconWhatsapp
								v-if="title === 'WhatsApp'"
								:stroke-width="1.5"
								color="var(--color-blue)"
							/>
							<IconInstagram
								v-if="title === 'Instagram'"
								:stroke-width="1.5"
								color="var(--color-blue)"
							/>
							<IconTiktok
								v-if="title === 'TikTok'"
								:stroke-width="1.5"
								color="var(--color-blue)"
							/>
						</NuxtLinkLocale>
					</li>
				</ul>
				<NuxtLink
					class="moser-metallbau button"
					to="https://moser-metall.ch"
					target="_blank"
					@click="trackEvent('Moser Metallbau')"
				>
					Moser Metallbau
				</NuxtLink>
			</nav>
			<div class="column">
				<FooterSlogan class="slogan" />
				<FooterAnimal />
			</div>
			<CodeCrush />
		</div>
	</footer>
</template>

<style scoped>
footer {
	position: relative;
	overflow: hidden;
	color: var(--color-white);
	background-color: var(--color-blue);

	.wrapper {
		display: grid;
		grid-auto-flow: row;
		grid-column: frame;
		gap: 2rem;

		padding-block: 5rem 1rem;

		@media (--small) {
			grid-template-columns: auto auto;
			grid-template-rows: 1fr auto;
		}

		@media (--large) {
			grid-template-columns: 1fr 1fr auto;
			grid-template-rows: 1fr auto;
		}
	}

	& :deep(a:not(.button)) {
		color: inherit;
		text-decoration: underline;
		text-decoration-color: var(--text-decoration-color, var(--color-blue));
		text-underline-offset: 0.2em;

		transition-duration: var(--transition-duration);

		&:hover {
			--text-decoration-color: var(--color-white);
		}
	}

	::selection {
		color: var(--color-blue);
		background-color: var(--color-white);
	}
}

.info {
	display: grid;
	gap: var(--gap);

	.address,
	.opening-hours {
		display: grid;
		font-style: normal;
		line-height: 1.4;
		white-space: pre-line;
	}

	.contact {
		margin-block-start: 1.5rem;
	}

	.email {
		white-space: nowrap;
	}

	& :deep(a:not([href^="tel:"])) {
		font-weight: var(--font-weight-bold);
	}

	@media (--medium) {
		grid-auto-flow: column;
		grid-template-columns: 1fr 1fr;
	}
}

nav {
	display: grid;
	grid-auto-flow: row;
	gap: 1rem;
	row-gap: 4rem;
	align-content: baseline;

	@media (--medium) {
		grid-auto-flow: column;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr auto;
		gap: var(--gap);
		align-self: start;
	}
}

ul {
	list-style: none;
}

.legal-menu {
	@media (--small) {
		grid-column: 2;
	}
}

.socials-menu {
	display: grid;
	grid-auto-flow: column;
	gap: 1rem;
	justify-content: start;

	.social-link {
		overflow: hidden;
		display: flex;
		place-content: center;
		place-items: center;

		width: 2rem;
		height: 2rem;

		background-color: var(--background-color, var(--color-white));
		border: 1px solid var(--border-color, var(--color-blue));
		border-radius: 50%;

		transition-duration: var(--transition-duration);

		&:hover {
			--background-color: var(--color-blue);
			--border-color: var(--color-white);
			--text-color: var(--color-white);
		}

		&:active {
			--scale: 0.8;
		}

		:deep(svg) {
			transform: scale(var(--scale, 1));
			width: 100%;
			height: 100%;
			transition-duration: var(--transition-duration);

			.circle,
			.icon {
				transition-duration: var(--transition-duration);
			}

			.circle {
				fill: var(--background-color, var(--color-white));
			}

			.icon {
				fill: var(--text-color, var(--color-blue));
				transition-delay: calc(var(--transition-duration) / 2);
			}
		}
	}

	@media (--medium) {
		grid-row: 2;
	}
}

.legal-link,
.more-link {
	font-weight: var(--font-weight-bold);
}

.moser-metallbau {
	transform: scale(var(--scale, 1));

	justify-self: start;

	width: min-content;
	height: min-content;
	padding: 0.6em 0.9em;

	font-weight: var(--font-weight-bold);
	line-height: 1;
	white-space: nowrap;

	border: 1px solid var(--color-white);

	transition-duration: var(--transition-duration);
	transition-property: color, background-color, transform;

	&:hover {
		color: var(--color-blue);
		background-color: var(--color-white);
	}

	&:active {
		--scale: 0.95;
	}

	@media (--small) {
		grid-row: 3;
	}

	@media (--medium) {
		grid-row: 2;
	}
}

.column {
	transform: translate(var(--translate, 0, 0));

	display: grid;
	gap: 2rem;
	place-content: end;
	place-items: end;

	.slogan {
		transform: translate(var(--translate, -3rem, 2rem));
		width: var(--width, 80%);
		max-width: 20rem;

		@media (--medium) {
			--translate: -8rem, 2rem;
		}

		@media (--large) {
			--width: 100%;
		}
	}

	& + :deep(.footer-animal) {
		--scale: 1;

		grid-column: -1;
		place-self: end;

		@media (--small) {
			grid-column: -1;
			grid-row: 2;
		}

		@media (--large) {
			--translate-x: 0;

			grid-column: -1;
		}
	}

	@media (--small) {
		grid-column: 2;
	}
}
</style>
