export const useSettingsStore = defineStore('Settings', () => {
	const settings = ref({})

	const consent = ref(false)

	const title = computed(() => {
		return settings.value?.title || ''
	})

	const description = computed(() => {
		return settings.value?.description || ''
	})

	const mainNavigation = computed(() => {
		return settings.value?.mainNavigation || []
	})

	const serviceNavigation = computed(() => {
		return settings.value?.serviceNavigation || []
	})

	const socialNavigation = computed(() => {
		return settings.value?.socialNavigation || []
	})

	const legalNavigation = computed(() => {
		return settings.value?.legalNavigation || []
	})

	const consentText = computed(() => {
		return settings.value?.consentText || ''
	})

	const address = computed(() => {
		return settings.value?.address || ''
	})

	const googleMapsLink = computed(() => {
		return settings.value?.googleMapsLink || ''
	})

	const phoneOffice = computed(() => {
		return settings.value?.phoneOffice || ''
	})

	const phoneService = computed(() => {
		return settings.value?.phoneService || ''
	})

	const email = computed(() => {
		return settings.value?.email || ''
	})

	const openingHours = computed(() => {
		return settings.value?.openingHours || ''
	})

	const moo = computed(() => {
		return settings.value?.moo || ''
	})

	const oink = computed(() => {
		return settings.value?.oink || ''
	})

	const agb = computed(() => {
		return settings.value?.agb?.filename || ''
	})

	const fetchSettings = async (language = useNuxtApp()?.$i18n?.locale?.value || 'de') => {
		try {
			const url = 'cdn/stories/settings'
			const { data } = await useStoryblokApi().get(url, {
				version: useRuntimeConfig().public.storyblokVersion,
				language,
				resolve_links: 'url'
			})
			settings.value = data.story.content || {}
		} catch (error) {
			throw createError(error)
		}
	}

	return {
		fetchSettings,
		settings,
		title,
		description,
		mainNavigation,
		serviceNavigation,
		socialNavigation,
		legalNavigation,
		consentText,
		consent,
		address,
		googleMapsLink,
		phoneOffice,
		phoneService,
		email,
		openingHours,
		moo,
		oink,
		agb
	}
}, {
	persist: {
		paths: ['consent']
	}
})

if (import.meta.hot) {
	import.meta.hot.accept(
		acceptHMRUpdate(
			useSettingsStore, import.meta.hot
		)
	)
}
