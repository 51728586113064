export const appRootId = "moser-stalleinrichtungen"

export const appRootTag = "body"

export const appLayoutTransition = {"name":"layout","mode":"out-in"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width,initial-scale=1"},{"name":"theme-color","content":"#253081"}],"link":[{"rel":"icon","href":"/favicon.svg","type":"image/svg+xml","hid":"favicon-svg"},{"rel":"icon","href":"/favicon.png","type":"image/png","hid":"favicon-png"},{"rel":"icon","href":"/favicon.ico","sizes":"any","hid":"favicon-ico"}],"style":[],"script":[],"noscript":[],"title":"Moser Stalleinrichtungen"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootAttrs = {"id":"moser-stalleinrichtungen"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"trailingSlash":"remove","componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#moser-stalleinrichtungen'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = false