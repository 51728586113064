
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93z7aO5jTHizMeta } from "/nuxt/pages/[...slug].vue?macro=true";
import { default as agbinmHMRu6QtMeta } from "/nuxt/pages/agb.vue?macro=true";
import { default as indexB7WWX0prYrMeta } from "/nuxt/pages/shop/account/index.vue?macro=true";
import { default as loginbMserti1ezMeta } from "/nuxt/pages/shop/account/login.vue?macro=true";
import { default as logoutk5VJHPQsYGMeta } from "/nuxt/pages/shop/account/logout.vue?macro=true";
import { default as _91orderid_93IFMpxDREqdMeta } from "/nuxt/pages/shop/account/orders/[orderid].vue?macro=true";
import { default as indexFGKCSNsdp6Meta } from "/nuxt/pages/shop/account/orders/index.vue?macro=true";
import { default as profilevROTffG7qwMeta } from "/nuxt/pages/shop/account/profile.vue?macro=true";
import { default as registerbC9CcCIfCgMeta } from "/nuxt/pages/shop/account/register.vue?macro=true";
import { default as shipping_45addressmICSJjZzGdMeta } from "/nuxt/pages/shop/account/shipping-address.vue?macro=true";
import { default as cartHVOtRgOINiMeta } from "/nuxt/pages/shop/cart.vue?macro=true";
import { default as _91handle_93NDmD01QKSSMeta } from "/nuxt/pages/shop/category/[handle].vue?macro=true";
import { default as checkoutZmCX4BXnpiMeta } from "/nuxt/pages/shop/checkout.vue?macro=true";
import { default as confirmationdOsY3lmRysMeta } from "/nuxt/pages/shop/confirmation.vue?macro=true";
import { default as indexQdTuS6RRSjMeta } from "/nuxt/pages/shop/index.vue?macro=true";
import { default as _91handle_93Tm6Ewon6ifMeta } from "/nuxt/pages/shop/product/[handle].vue?macro=true";
import { default as _91_91term_93_93NDT1WxVNi8Meta } from "/nuxt/pages/shop/search/[[term]].vue?macro=true";
import { default as component_45stubcT5lM54JzLMeta } from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubcT5lM54JzL } from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de",
    path: "/:slug(.*)*",
    component: () => import("/nuxt/pages/[...slug].vue")
  },
  {
    name: "slug___fr",
    path: "/fr/:slug(.*)*",
    component: () => import("/nuxt/pages/[...slug].vue")
  },
  {
    name: "agb___de",
    path: "/agb",
    component: () => import("/nuxt/pages/agb.vue")
  },
  {
    name: "agb___fr",
    path: "/fr/agb",
    component: () => import("/nuxt/pages/agb.vue")
  },
  {
    name: "account___de",
    path: "/shop/konto",
    meta: indexB7WWX0prYrMeta || {},
    component: () => import("/nuxt/pages/shop/account/index.vue")
  },
  {
    name: "account___fr",
    path: "/fr/shop/konto",
    meta: indexB7WWX0prYrMeta || {},
    component: () => import("/nuxt/pages/shop/account/index.vue")
  },
  {
    name: "login___de",
    path: "/shop/login",
    meta: loginbMserti1ezMeta || {},
    component: () => import("/nuxt/pages/shop/account/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/shop/login",
    meta: loginbMserti1ezMeta || {},
    component: () => import("/nuxt/pages/shop/account/login.vue")
  },
  {
    name: "logout___de",
    path: "/shop/konto/abmelden",
    meta: logoutk5VJHPQsYGMeta || {},
    component: () => import("/nuxt/pages/shop/account/logout.vue")
  },
  {
    name: "logout___fr",
    path: "/fr/shop/konto/abmelden",
    meta: logoutk5VJHPQsYGMeta || {},
    component: () => import("/nuxt/pages/shop/account/logout.vue")
  },
  {
    name: "orderDetail___de",
    path: "/shop/konto/bestellungen/:orderid",
    meta: _91orderid_93IFMpxDREqdMeta || {},
    component: () => import("/nuxt/pages/shop/account/orders/[orderid].vue")
  },
  {
    name: "orderDetail___fr",
    path: "/fr/shop/konto/bestellungen/:orderid",
    meta: _91orderid_93IFMpxDREqdMeta || {},
    component: () => import("/nuxt/pages/shop/account/orders/[orderid].vue")
  },
  {
    name: "account-orders___de",
    path: "/shop/konto/bestellungen",
    meta: indexFGKCSNsdp6Meta || {},
    component: () => import("/nuxt/pages/shop/account/orders/index.vue")
  },
  {
    name: "account-orders___fr",
    path: "/fr/shop/konto/bestellungen",
    meta: indexFGKCSNsdp6Meta || {},
    component: () => import("/nuxt/pages/shop/account/orders/index.vue")
  },
  {
    name: "account-profile___de",
    path: "/shop/konto/profil",
    meta: profilevROTffG7qwMeta || {},
    component: () => import("/nuxt/pages/shop/account/profile.vue")
  },
  {
    name: "account-profile___fr",
    path: "/fr/shop/konto/profil",
    meta: profilevROTffG7qwMeta || {},
    component: () => import("/nuxt/pages/shop/account/profile.vue")
  },
  {
    name: "register___de",
    path: "/shop/registrieren",
    meta: registerbC9CcCIfCgMeta || {},
    component: () => import("/nuxt/pages/shop/account/register.vue")
  },
  {
    name: "register___fr",
    path: "/fr/shop/registrieren",
    meta: registerbC9CcCIfCgMeta || {},
    component: () => import("/nuxt/pages/shop/account/register.vue")
  },
  {
    name: "account-shipping-address___de",
    path: "/shop/konto/lieferadresse",
    meta: shipping_45addressmICSJjZzGdMeta || {},
    component: () => import("/nuxt/pages/shop/account/shipping-address.vue")
  },
  {
    name: "account-shipping-address___fr",
    path: "/fr/shop/konto/lieferadresse",
    meta: shipping_45addressmICSJjZzGdMeta || {},
    component: () => import("/nuxt/pages/shop/account/shipping-address.vue")
  },
  {
    name: "cart___de",
    path: "/shop/warenkorb",
    meta: cartHVOtRgOINiMeta || {},
    component: () => import("/nuxt/pages/shop/cart.vue")
  },
  {
    name: "cart___fr",
    path: "/fr/shop/warenkorb",
    meta: cartHVOtRgOINiMeta || {},
    component: () => import("/nuxt/pages/shop/cart.vue")
  },
  {
    name: "categoryOverview___de",
    path: "/shop/kategorie/:handle",
    meta: _91handle_93NDmD01QKSSMeta || {},
    component: () => import("/nuxt/pages/shop/category/[handle].vue")
  },
  {
    name: "categoryOverview___fr",
    path: "/fr/shop/kategorie/:handle",
    meta: _91handle_93NDmD01QKSSMeta || {},
    component: () => import("/nuxt/pages/shop/category/[handle].vue")
  },
  {
    name: "checkout___de",
    path: "/shop/kasse",
    meta: checkoutZmCX4BXnpiMeta || {},
    component: () => import("/nuxt/pages/shop/checkout.vue")
  },
  {
    name: "checkout___fr",
    path: "/fr/shop/kasse",
    meta: checkoutZmCX4BXnpiMeta || {},
    component: () => import("/nuxt/pages/shop/checkout.vue")
  },
  {
    name: "confirmation___de",
    path: "/shop/bestaetigung",
    meta: confirmationdOsY3lmRysMeta || {},
    component: () => import("/nuxt/pages/shop/confirmation.vue")
  },
  {
    name: "confirmation___fr",
    path: "/fr/shop/bestaetigung",
    meta: confirmationdOsY3lmRysMeta || {},
    component: () => import("/nuxt/pages/shop/confirmation.vue")
  },
  {
    name: "shop___de",
    path: "/shop",
    meta: indexQdTuS6RRSjMeta || {},
    component: () => import("/nuxt/pages/shop/index.vue")
  },
  {
    name: "shop___fr",
    path: "/fr/shop",
    meta: indexQdTuS6RRSjMeta || {},
    component: () => import("/nuxt/pages/shop/index.vue")
  },
  {
    name: "shop-product-detail___de",
    path: "/shop/produkt/:handle",
    meta: _91handle_93Tm6Ewon6ifMeta || {},
    component: () => import("/nuxt/pages/shop/product/[handle].vue")
  },
  {
    name: "shop-product-detail___fr",
    path: "/fr/shop/produkt/:handle",
    meta: _91handle_93Tm6Ewon6ifMeta || {},
    component: () => import("/nuxt/pages/shop/product/[handle].vue")
  },
  {
    name: "product-search___de",
    path: "/shop/suche/:term?",
    meta: _91_91term_93_93NDT1WxVNi8Meta || {},
    component: () => import("/nuxt/pages/shop/search/[[term]].vue")
  },
  {
    name: "product-search___fr",
    path: "/fr/shop/suche/:term?",
    meta: _91_91term_93_93NDT1WxVNi8Meta || {},
    component: () => import("/nuxt/pages/shop/search/[[term]].vue")
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/home/news.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/firma/geschichte.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/firma/geschichte.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/firma/kontakt.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/firma/kontakt.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/firma/stellenangebot.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/firma/stellenangebot.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/firma/ueber-uns.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/firma/ueber-uns.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/fuetterung/automatische-fuetterung.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/fuetterung/automatische-fuetterung.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/kuhtreiber.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/kuhtreiber.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkroboter.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkroboter.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-butterfly.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-butterfly.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-expressway.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-expressway.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-fischgraete.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-fischgraete.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-karussell.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-karussell.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-parabon.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-parabon.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-rohrmelkanlage.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-rohrmelkanlage.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-swingover.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-swingover.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-tandem.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melkstand-tandem.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melktechnik.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/melktechnik/melktechnik.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/planung/planen-und-bauen.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/planung/planen-und-bauen.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/anbindestall.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/anbindestall.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/laufstall.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/laufstall.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/mobile-line.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/mobile-line.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/moocall.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/moocall.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/munimaststall.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/munimaststall.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/mutterkuhstall.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/rindviehstall/mutterkuhstall.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/schweinestall/alphanest.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/schweinestall/alphanest.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/schweinestall/maststall.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/schweinestall/maststall.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/schweinestall/primo-elementstall.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/schweinestall/primo-elementstall.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/schweinestall/zuchtstall.html",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/de/schweinestall/zuchtstall.html&prev=search&pto=aue",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/Downloads/FloStarMax.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/fuetterung/Bandfuetterung.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/fuetterung/Fuetterungsroboter.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/melktechnik/Melkroboter_Boumatic.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/melktechnik/Parabone.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/melktechnik/Stylline%20Supreme_Fischgr%C3%A4te.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/melktechnik/Xpedia_360EX.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/melktechnik/Xpedia%20360IX.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/melktechnik/Xpressway%20Parallel.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/rindviehstall/Laufhof%20mit%20Kuhtreiber.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/rindviehstall/mobile_liegehuette.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/schweinestall/alphanest/Beitrag_Goetz_20-07.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/schweinestall/alphanest/bz_s16.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/schweinestall/alphanest/gruene_100106.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/schweinestall/alphanest/mueller_group.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/schweinestall/alphanest/mueller_hauszeitung.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/schweinestall/alphanest/sp_s24-26.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/schweinestall/alphanest/tg-bericht.pdf",
    component: component_45stubcT5lM54JzL
  },
  {
    name: component_45stubcT5lM54JzLMeta?.name,
    path: "/downloads/schweinestall/alphanest/WilerZeitung.pdf",
    component: component_45stubcT5lM54JzL
  }
]