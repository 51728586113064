export const useNavigationVisibility = () =>
	useState(
		'navigationVisibility',
		() => false
	)

export const useHeaderHeight = () =>
	useState(
		'headerHeight',
		() => 0
	)
export const useFooterHeight = () =>
	useState(
		'footerHeight',
		() => 0
	)
export const useHeaderVisibility = () =>
	useState(
		'headerVisibility',
		() => true
	)
export const useSearchVisibility = () =>
	useState(
		'searchVisibility',
		() => false
	)

export const useSidebarVisibility = () =>
	useState(
		'sidebarVisibility',
		() => false
	)
export const useBackToLink = () =>
	useState(
		'backToLink',
		() => '/shop'
	)

export const useCurrentLayout = () => {
	const route = useRoute()
	const currentLayout = ref('default')

	watch(() => route.meta.layout, (newLayout) => {
		currentLayout.value = newLayout || 'default'
	}, { immediate: true })

	return currentLayout
}

export const useShopLayout = () => {
	const route = useRoute()
	const shopLayout = ref('products')
	const { customerIsAuthenticated } = storeToRefs(useShopStore())

	watch(() => [route.path, customerIsAuthenticated.value], ([path, customerIsAuthenticated]) => {
		if (path.startsWith('/shop/konto') && customerIsAuthenticated) {
			shopLayout.value = 'account'
		} else if (path.startsWith('/shop/warenkorb') || path.startsWith('/shop/kasse')) {
			shopLayout.value = 'cart'
		} else {
			shopLayout.value = 'products'
		}
	}, { immediate: true })

	return shopLayout
}
