export const useShopStore = defineStore(
	'Shop',
	() => {
		const products = ref([])
		const productsInCategory = ref(0)
		const categories = ref([])
		const orders = ref([])
		const productTypes = ref([])
		const offset = ref(1)
		const limit = ref(12)
		const cart = ref({})
		const activeCategory = ref(null)
		const customer = ref(null)
		const shopLayout = ref('products')

		const shippingOptions = ref([])

		const productFilter = ref('')
		const categoryFilter = ref('')

		const fetchProducts = async (deleteProducts = true) => {
			// const medusa = useMedusaClient()

			try {
				// eslint-disable-next-line no-console
				console.log('Aktive Kategorie:', activeCategory.value)

				const { data: { value: { products: medusaProducts, count } } } = await useFetch(`/api/shop/medusa/products-sorted?limit=${limit.value}&page=${offset.value}&categoryId=${activeCategory.value ? activeCategory.value : ''}`)
				if (deleteProducts) {
					products.value = []
				}
				productsInCategory.value = count
				medusaProducts.forEach((product) => {
					products.value.push(product)
				})

				return {
					products: products.value,
					count: productsInCategory.value
				}
			} catch (error) {
				throw createError(error)
			}
		}

		const fetchCategories = async () => {
			try {
				const { data } = await useFetch('/api/shop/medusa/categories')
				categories.value = data.value
			} catch (error) {
				throw createError(error)
			}
		}

		const fetchProductTypes = async () => {
			try {
				const { data } = await useFetch('/api/shop/medusa/product-types')
				productTypes.value = data.value
			} catch (error) {
				throw createError(error)
			}
		}

		const fetchOrder = async (id) => {
			try {
				const { data } = await useFetch('/api/shop/medusa/orders?id=' + id)
				return data.value
			} catch (error) {
				throw createError(error)
			}
		}

		const initCart = async () => {
			try {
				const { data } = await useFetch('/api/shop/medusa/cart')
				cart.value = data.value
			} catch (error) {
				throw createError(error)
			}
		}

		const refreshCart = async () => {
			try {
				const { data } = await useFetch('/api/shop/medusa/cart', { method: 'POST' })
				cart.value = data.value
			} catch (error) {
				throw createError(error)
			}
		}

		const resetCart = async () => {
			try {
				const { data } = await useFetch('/api/shop/medusa/cart', { method: 'DELETE' })
				cart.value = data.value
			} catch (error) {
				throw createError({
					statusCode: 500,
					message: 'Fehler beim Zurücksetzen des Warenkorbs.',
					data: error
				})
			}
		}

		const setCart = (data) => {
			cart.value = data
		}

		const getShippingOptions = async () => {
			const medusa = useMedusaClient()

			const {
				shipping_options: medusaShippingOptions
			} = await medusa.shippingOptions.listCartOptions(cart?.value?.id)

			shippingOptions.value = medusaShippingOptions
		}

		const getProductTypes = async () => {
			return productTypes.value.filter((productType) => {
				return productType.value === 'Produkt' || productType.value === 'Kit'
			})
		}

		const cartId = computed(() => {
			return cart?.value?.id
		})

		const cartItems = computed(() => {
			return cart.value?.items.filter((item) => item.metadata.type !== 'surcharge' && item.metadata.type !== 'packaging-surcharge') || []
		})

		const cartSurcharge = computed(() => {
			return cart.value?.items.filter((item) => item.metadata.type === 'surcharge')
		})

		const cartPackagingSurcharge = computed(() => {
			return cart.value?.items.filter((item) => item.metadata.type === 'packaging-surcharge')
		})

		const cartItemsCount = computed(() => {
			return cart.value?.items?.reduce(
				(acc, { quantity, metadata }) => {
					if (!(metadata?.type === 'surcharge' || metadata?.type === 'packaging-surcharge')) {
						return acc + quantity
					}
					return acc
				},
				0
			)
		})

		const shippingAddress = computed(() => {
			return customer.value?.shipping_addresses?.[0]
		})

		const customerIsAuthenticated = computed(() => {
			return !!customer.value
		})

		const loginCustomer = async () => {
			try {
				// const { data } = await useFetch('/api/shop/medusa/customer')
				// customervalue = data.value
				customer.value = await useRequestFetch()('/api/shop/medusa/customer')
			} catch (error) {
				throw createError(error)
			}
		}

		const setActiveCategory = (handle) => {
			// products.value = []
			offset.value = 1

			const findCategoryByHandle = (categories, handle) => {
				if (!categories) {
					return null
				}
				for (const category of categories) {
					if (category.handle === handle) {
						return category
					}
					if (category.children && category.children.length > 0) {
						const foundCategory = findCategoryByHandle(category.children, handle)
						if (foundCategory) {
							return foundCategory
						}
					}
				}
				return null
			}

			const category = findCategoryByHandle(categories.value, handle)

			if (category) {
				activeCategory.value = category.id
			} else {
				activeCategory.value = null
			}
		}

		const increaseOffset = () => {
			offset.value = offset.value + 1
		}

		const getVariantBySku = (sku) => {
			const product = products.value.find((product) => {
				return product.variants.find((variant) => variant?.sku === sku)
			})

			if (product === undefined) {
				throw createError({
					statusCode: 404,
					message: `Das Produkt mit SKU «${sku}» wurde nicht gefunden.`
				})
			}

			const variant = product.variants.find((variant) => variant.sku === sku)

			variant.images = product.images.map((image, index) => {
				image.metadata = product.metadata.images[index]
				return image
			})

			const otherVariants = product.variants
				.filter((otherVariant) => otherVariant.sku !== sku)
				.map(({ sku, metadata: { variant_label: title } }) => {
					return {
						sku,
						title
					}
				})

			return {
				variant,
				otherVariants
			}
		}

		const getProductByHandle = async (handle) => {
			const medusa = useMedusaClient()

			const { products } = await medusa.products.list({
				limit: 1,
				handle: handle
			})

			const product = products[0]

			if (product === undefined) {
				throw createError({
					statusCode: 404,
					message: `Das Produkt mit Handle «${handle}» wurde nicht gefunden.`
				})
			}

			return product
		}

		const getProductBySKU = async (sku) => {
			const medusa = useMedusaClient()

			const { products } = await medusa.products.list({
				limit: 1,
				q: sku
			})

			const product = products[0]

			if (product === undefined) {
				throw createError({
					statusCode: 404,
					message: `Das Produkt mit SKU «${sku}» wurde nicht gefunden.`
				})
			}

			return product
		}

		const getProductByID = async (id) => {
			const medusa = useMedusaClient()

			const { product } = await medusa.products.retrieve(id)

			if (product === undefined) {
				throw createError({
					statusCode: 404,
					message: `Das Produkt mit ID «${id}» wurde nicht gefunden.`
				})
			}

			return product
		}

		const getProductsBySearchTerm = async (searchTerm) => {
			const medusa = useMedusaClient()
			try {
				// regular expression, if customer begins to enter sku "xxxxxx.xxx"
				let types = []
				if (!(/^\d{6}\.\d{3}$/.test(searchTerm))) {
					types = (await getProductTypes()).map((type) => type.id)
				}

				const { products } = await medusa.products.list({
					limit: 50,
					order: 'sort',
					type_id: types,
					q: searchTerm.normalize('NFKD').trim()
				})

				return products
			} catch (error) {
				throw createError({
					statusCode: 500,
					message: 'Es gibt keine Produkte zu filtern.',
					error
				})
			}
		}

		const getProducts = computed(() => {
			try {
				return products.value || []
			} catch (error) {
				throw createError({
					statusCode: 404,
					message: 'Es sind keine Produkte verfügbar.',
					data: error
				})
			}
		})

		const findCategoryById = (categories, id) => {
			if (!categories) {
				return null
			}
			for (const category of categories) {
				if (category.id === id) {
					return category
				}
				if (category.children && category.children.length > 0) {
					const foundCategoryName = findCategoryById(category.children, id)
					if (foundCategoryName) {
						return foundCategoryName
					}
				}
			}
			return null
		}

		const getCurrentCategory = computed(() => {
			if (activeCategory.value === null) {
				return null
			}

			return findCategoryById(categories.value, activeCategory.value) || null
		})

		const getCategoriesAtSameLevel = (categoryId) => {
			const findParentCategory = (categories, id) => {
				for (const category of categories) {
					if (category.children && category.children.some((child) => child.id === id)) {
						return category
					}
					if (category.children && category.children.length > 0) {
						const foundParent = findParentCategory(category.children, id)
						if (foundParent) {
							return foundParent
						}
					}
				}
				return null
			}

			const parentCategory = findParentCategory(categories.value, categoryId)
			return parentCategory ? { parent: parentCategory, children: parentCategory.children } : { parent: null, children: [] }
		}

		const getBreadcrumbPath = (categoryId) => {
			const findCategoryPath = (categories, id, path = []) => {
				for (const category of categories) {
					if (category.id === id) {
						return [...path, { id: category.id, name: category.name, handle: category.handle }]
					}
					if (category.children && category.children.length > 0) {
						const foundPath = findCategoryPath(category.children, id, [...path, { id: category.id, name: category.name, handle: category.handle }])
						if (foundPath.length > 0) {
							return foundPath
						}
					}
				}
				return []
			}

			return findCategoryPath(categories.value, categoryId)
		}

		const applyDiscount = async (code) => {
			const medusa = useMedusaClient()
			const { cart: newCart } = await medusa.carts.update(cartId.value, {
				discounts: [
					{
						code
					}
				]
			})

			cart.value = newCart
			return cart
		}

		const removeDiscount = async () => {
			const medusa = useMedusaClient()
			const code = cart.value.discounts[0].code

			const { cart: newCart } = await medusa.carts.deleteDiscount(cartId.value, code)

			cart.value = newCart
			return cart
		}

		return {
			products,
			productsInCategory,
			getProducts,
			productTypes,
			fetchProductTypes,
			getProductTypes,
			cart,
			cartId,
			cartItems,
			cartItemsCount,
			cartSurcharge,
			cartPackagingSurcharge,
			shippingOptions,
			initCart,
			refreshCart,
			resetCart,
			setCart,
			customer,
			shippingAddress,
			customerIsAuthenticated,
			loginCustomer,
			fetchOrder,
			orders,
			shopLayout,
			categories,
			setActiveCategory,
			activeCategory,
			getCategoriesAtSameLevel,
			increaseOffset,
			getCurrentCategory,
			getVariantBySku,
			getProductByHandle,
			getProductByID,
			getProductBySKU,
			getProductsBySearchTerm,
			getShippingOptions,
			productFilter,
			categoryFilter,
			fetchCategories,
			fetchProducts,
			getBreadcrumbPath,
			applyDiscount,
			removeDiscount
		}
	}
)

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useShopStore, import.meta.hot))
}
