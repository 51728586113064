import revive_payload_client_MUXznPndcn from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AFog5NgdbM from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_od2Lkql9Dr from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_GnhEubvmT9 from "/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.1.0_@types+node@22.13.1_jiti@2.4.2_terser@5.38._fj3yfcxpttnv4azryepzee53gi/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Im4M2Ao7G7 from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2evNQTSl9a from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oP14KZjqEx from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ODQ174e8GK from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_KfbI4lR8CT from "/nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_7cvvSsrxtC from "/nuxt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@9.20.0_jiti@2.4.2__ior_wjwpgupcyntu5ojzipmla3pl6y/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_oRsbxdjelE from "/nuxt/node_modules/.pnpm/nuxt-easy-lightbox@1.0.2_magicast@0.3.5_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import plugin_client_9oBSbMrX51 from "/nuxt/node_modules/.pnpm/nuxt-fathom@0.0.2_magicast@0.3.5/node_modules/nuxt-fathom/dist/runtime/plugin.client.mjs";
import plugin_15ZHL1BRLo from "/nuxt/node_modules/.pnpm/nuxt-medusa@0.5.0_@medusajs+medusa@1.20.11_@mikro-orm+migrations@5.9.7_@types+ioredis-mock@8._lqlikrharchblr4yop5lkqn2ym/node_modules/nuxt-medusa/dist/runtime/plugin.mjs";
import plugin_N7gNWcaJR2 from "/nuxt/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import switch_locale_path_ssr_8CME6jUCcG from "/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_A3fR5Y2vwR from "/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import titles_eWDj6yTOS4 from "/nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_@unhead+vue@1.11.18_vue@3.5.13_typescript@5.7.3___h3@1.15.0_magicast@_wgqbzcnatcycftvmg666dcro7u/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaultsWaitI18n_ITiu1JVAWe from "/nuxt/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.19_@unhead+vue@1.11.18_vue@3.5.13_typescript@5.7.3___h3@1.15.0_magicast@_wgqbzcnatcycftvmg666dcro7u/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaultsWaitI18n.js";
import plugin_DYaFoorEQb from "/nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.7.3_vue_ldbosh2iqzb36n5r2qz37gqipm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import i18n_sVHQBgnb3t from "/nuxt/plugins/i18n.js";
import init_settings_I3A4xxpN6C from "/nuxt/plugins/init.settings.js";
import init_shop_UtZtdumK3p from "/nuxt/plugins/init.shop.js";
import layout_y7VwhimnlW from "/nuxt/plugins/layout.js";
import navigation_loading_xImibWTvBI from "/nuxt/plugins/navigation-loading.js";
import sentry_PZVGm6LH3G from "/nuxt/plugins/sentry.js";
export default [
  revive_payload_client_MUXznPndcn,
  unhead_AFog5NgdbM,
  router_od2Lkql9Dr,
  _0_siteConfig_GnhEubvmT9,
  payload_client_Im4M2Ao7G7,
  navigation_repaint_client_2evNQTSl9a,
  check_outdated_build_client_oP14KZjqEx,
  chunk_reload_client_ODQ174e8GK,
  plugin_vue3_KfbI4lR8CT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_7cvvSsrxtC,
  plugin_oRsbxdjelE,
  plugin_client_9oBSbMrX51,
  plugin_15ZHL1BRLo,
  plugin_N7gNWcaJR2,
  switch_locale_path_ssr_8CME6jUCcG,
  i18n_A3fR5Y2vwR,
  titles_eWDj6yTOS4,
  defaultsWaitI18n_ITiu1JVAWe,
  plugin_DYaFoorEQb,
  i18n_sVHQBgnb3t,
  init_settings_I3A4xxpN6C,
  init_shop_UtZtdumK3p,
  layout_y7VwhimnlW,
  navigation_loading_xImibWTvBI,
  sentry_PZVGm6LH3G
]