export default defineNuxtPlugin(() => {
	const loading = ref(false)

	const startLoading = () => {
		loading.value = true
	}

	const stopLoading = () => {
		loading.value = false
	}

	// Global navigation hooks
	const router = useRouter()
	router.beforeEach((to, from, next) => {
		if (to.path !== from.path) {
			startLoading()
		}
		next()
	})

	router.afterEach(() => {
		// Kleine Verzögerung um zu schnelles Flackern zu vermeiden
		setTimeout(stopLoading, 200)
	})

	return {
		provide: {
			loading
		}
	}
})
