<script setup>
import Headroom from 'headroom.js'

const header = ref(null)
const headerHeight = useHeaderHeight()
const { height } = useElementSize(header)

watchEffect(() => {
	headerHeight.value = height
})

const isHeaderVisible = useHeaderVisibility()

onMounted(() => {
	const options = {
		offset: {
			up: 0,
			down: headerHeight.value || 0
		},
		tolerance: {
			up: 0,
			down: 0
		},
		classes: {
			pinned: 'pinned',
			unpinned: 'unpinned',
			top: 'top',
			notTop: 'not-top',
			bottom: 'bottom',
			notBottom: 'not-bottom'
		},
		onPin: () => {
			isHeaderVisible.value = true
		},
		onUnpin: () => {
			isHeaderVisible.value = false
		},
		onTop: () => {
			isHeaderVisible.value = true
		},
		onNotTop: () => {
			isHeaderVisible.value = false
		},
		onBottom: () => {
			isHeaderVisible.value = true
		}
	}
	const headroom = new Headroom(header.value, options)
	headroom.init()
})

const navigationVisible = useNavigationVisibility()
</script>

<template>
	<header
		ref="header"
		class="header grid"
		:data-navigation="navigationVisible ? 'visible' : 'hidden'"
	>
		<div class="wrapper">
			<LogoElement />
			<AppNavigation />
		</div>
	</header>
</template>

<style scoped>
.header {
	--foreground-color: var(--color-white);
	--background-color: transparent;

	position: fixed;
	z-index: 3;
	inset: 0 0 auto;

	color: var(--foreground-color);

	transition-timing-function: var(--ease-out-quart);
	transition-duration: var(--transition-duration);
	transition-property: height;

	&::before {
		pointer-events: none;
		content: '';

		position: absolute;
		z-index: -1;
		inset: 0;
		transform: translateY(var(--header-y, 0));

		background-color: var(--background-color, transparent);
		border-block-end: 1px solid var(--header-border-color, transparent);

		transition-duration: 1200ms, 1200ms, 200ms;
		transition-property: opacity, background-color, border-color;
	}

	.wrapper {
		display: flex;
		grid-column: frame;
		gap: var(--gap);
		align-items: center;
		justify-content: space-between;

		height: var(--header-height);
	}

	&[data-navigation='visible'] {
		@media (--mobile-menu) {
			--logo-color: var(--color-white);
			--logo-transition-delay: 640ms;

			&::before {
				background-image:
					linear-gradient(to bottom,
						var(--color-blue),
						color-mix(in srgb, var(--color-blue) 0%, transparent 100%));
			}
		}
	}

	&[data-navigation='hidden'] {
		--header-y: 0;
		--logo-y: 0;

		&::before,
		:deep(.menu),
		:deep(.menu-button),
		.logo {
			transition-timing-function: var(--ease-out-quart);
			transition-duration: 800ms;
			transition-property: opacity, background-color, border-color, transform;
		}
	}

	&.top {
		&[data-navigation='hidden'] {
			@media (--small) {
				--logo-scale: 1.05;
			}

			@media (--medium) {
				--logo-scale: 1.15;
				--logo-y: -5%;
			}

			@media (--large) {
				--logo-scale: 1.25;
				--logo-y: -5%;
			}
		}
	}

	&.unpinned {
		&[data-navigation='hidden'] {
			--header-border-color: transparent;
			--header-y: calc(var(--header-height) * -1);

			pointer-events: none;
		}
	}

	&.bottom {
		--header-y: 0;
	}

	&.not-top:not([data-navigation='visible']) {
		--transition-delay: 0s;
		--background-color: var(--color-paper-white);
		--foreground-color: var(--color-blue);
		--header-height: var(--header-height-collapsed);
		--header-border-color: var(--color-grey);
	}
}
</style>
