<script setup>
import Moo from 'assets/images/moo.svg'
import Oink from 'assets/images/oink.svg'

const settingsStore = useSettingsStore()
const {
	moo,
	oink
} = toRefs(settingsStore)

const currentPath = computed(() => {
	const { path } = useRoute()
	return path
})

const isMoo = computed(() => {
	const { path: currentPath } = useRoute()
	const path = moo.value?.cached_url?.replace(/\/$/, '')
	return currentPath.startsWith(`/${path}`)
})

const isOink = computed(() => {
	const { path: currentPath } = useRoute()
	const path = oink.value?.cached_url?.replace(/\/$/, '')
	return currentPath.startsWith(`/${path}`)
})

const animal = shallowRef(isMoo.value ? Moo : Oink)
watch(currentPath, () => {
	if (isMoo.value || isOink.value) {
		animal.value = isMoo.value ? Moo : Oink
	} else {
		animal.value = Math.random() > 0.5 ? Moo : Oink
	}
}, { immediate: true })
</script>

<template>
	<div class="footer-animal">
		<ClientOnly>
			<Transition
				name="fade"
				mode="out-in"
			>
				<Component
					:is="animal"
					:key="animal.toString()"
				/>
			</Transition>
		</ClientOnly>
	</div>
</template>

<style scoped>
.footer-animal {
	transform: scale(var(--scale, 1)) translate(var(--translate-x, 0), var(--translate-y, 0));
	grid-column: 1 / span 1;
	width: 10rem;
	height: 10rem;

	& svg {
		width: 100%;
		height: 100%;
	}
}
</style>
