<script setup>
import Logo from 'assets/images/logo.svg'

const { locale } = useI18n()
const link = computed(() => {
	return locale.value !== 'de' ? `/${locale.value}` : '/'
})

const { trackEvent } = useFathom()

const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	})

	if (useRoute().path !== '/') {
		trackEvent('Logo: zur Startseite')
	} else {
		trackEvent('Logo: nach oben scrollen')
	}
}
</script>

<template>
	<NuxtLink
		:to="link"
		class="logo"
		aria-label="Logo"
		@click="scrollToTop"
	>
		<Logo />
	</NuxtLink>
</template>

<style scoped>
.logo {
	cursor: pointer;

	z-index: 1;
	transform-origin: var(--logo-origin, 0 50%);
	transform: scale(var(--logo-scale, 1)) translateY(var(--header-y, 0));

	display: flex;

	max-width: var(--logo-width, 14rem);

	transition-timing-function: var(--ease-out-quint);
	transition-duration: 320ms;
	transition-property: transform, width;

	svg {
		transform: translateY(var(--logo-y, 0));
		width: 100%;
		height: 100%;

		:deep(path) {
			fill: var(--logo-color, currentColor);
			transition-delay: var(--logo-transition-delay, 0s);
			transition-duration: var(--transition-duration);
		}
	}

	@media (--small) {
		--logo-width: 20rem;
	}

	@media (--medium) {
		--logo-width: 20rem;
		--logo-origin: 50% 0;
	}
}
</style>
