export const getImageDimensions = (url) => {
	const dimensions = url.split('/')[5].split('x')
	const width = Number.parseInt(dimensions[0])
	const height = Number.parseInt(dimensions[1])
	return { width, height }
}

export const getFocusByDimensions = (focalPoint, dimensions) => {
	const { width, height } = dimensions
	const focus = focalPoint?.split(':')[0].split('x')

	const x = Number.parseInt(focus?.[0])
	const y = Number.parseInt(focus?.[1])

	if (!x || !y) {
		return '50% 50%'
	}

	const xPercent = ((x / width) * 100).toFixed(2)
	const yPercent = ((y / height) * 100).toFixed(2)

	return `${xPercent}% ${yPercent}%`
}

export const resolveLink = (link) => {
	if (link.linktype === 'url') {
		return link.url
	}

	if (!link?.story) {
		return link.cached_url
	}

	const { full_slug: slug } = link.story
	return `/${slug.replace(/^\/|\/$/g, '')}`
}
