import { defineNuxtPlugin } from '#app/nuxt'
import { LazyCard, LazyCards, LazyContent, LazyFollowUp, LazyGallery, LazyGoogleMaps, LazyHero, LazyImage, LazyLinkList, LazyPeople, LazyPerson, LazyProduct, LazyProductItem, LazyShopTeaser, LazySpotlight, LazyTile, LazyTiles, LazyVideo, LazyHome, LazyOverview, LazyPage, LazySvgoAccount, LazySvgoArrow, LazySvgoCart, LazySvgoChevron, LazySvgoClose, LazySvgoCross, LazySvgoCursor, LazySvgoFacebook, LazySvgoFuerEnGuetaeStall, LazySvgoInstagram, LazySvgoLinkedin, LazySvgoLoading, LazySvgoLogo, LazySvgoMail, LazySvgoMarker, LazySvgoMenu, LazySvgoMoo, LazySvgoOink, LazySvgoPhone, LazySvgoSearch, LazySvgoSwitzerland, LazySvgoTiktok, LazySvgoTrash, LazySvgoWeb, LazySvgoWhatsapp, LazySvgoYoutube } from '#components'
const lazyGlobalComponents = [
  ["Card", LazyCard],
["Cards", LazyCards],
["Content", LazyContent],
["FollowUp", LazyFollowUp],
["Gallery", LazyGallery],
["GoogleMaps", LazyGoogleMaps],
["Hero", LazyHero],
["Image", LazyImage],
["LinkList", LazyLinkList],
["People", LazyPeople],
["Person", LazyPerson],
["Product", LazyProduct],
["ProductItem", LazyProductItem],
["ShopTeaser", LazyShopTeaser],
["Spotlight", LazySpotlight],
["Tile", LazyTile],
["Tiles", LazyTiles],
["Video", LazyVideo],
["Home", LazyHome],
["Overview", LazyOverview],
["Page", LazyPage],
["SvgoAccount", LazySvgoAccount],
["SvgoArrow", LazySvgoArrow],
["SvgoCart", LazySvgoCart],
["SvgoChevron", LazySvgoChevron],
["SvgoClose", LazySvgoClose],
["SvgoCross", LazySvgoCross],
["SvgoCursor", LazySvgoCursor],
["SvgoFacebook", LazySvgoFacebook],
["SvgoFuerEnGuetaeStall", LazySvgoFuerEnGuetaeStall],
["SvgoInstagram", LazySvgoInstagram],
["SvgoLinkedin", LazySvgoLinkedin],
["SvgoLoading", LazySvgoLoading],
["SvgoLogo", LazySvgoLogo],
["SvgoMail", LazySvgoMail],
["SvgoMarker", LazySvgoMarker],
["SvgoMenu", LazySvgoMenu],
["SvgoMoo", LazySvgoMoo],
["SvgoOink", LazySvgoOink],
["SvgoPhone", LazySvgoPhone],
["SvgoSearch", LazySvgoSearch],
["SvgoSwitzerland", LazySvgoSwitzerland],
["SvgoTiktok", LazySvgoTiktok],
["SvgoTrash", LazySvgoTrash],
["SvgoWeb", LazySvgoWeb],
["SvgoWhatsapp", LazySvgoWhatsapp],
["SvgoYoutube", LazySvgoYoutube],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
