<script setup>
const navigationVisible = useNavigationVisibility()

const { mainNavigation } = storeToRefs(useSettingsStore())

const menuButton = ref(null)
const menuButtonVisible = useElementVisibility(menuButton)
const toggleNavigation = () => {
	if (!menuButtonVisible.value) {
		return
	}
	navigationVisible.value = !navigationVisible.value
}

const { width: viewportWidth } = useWindowSize()
watch(viewportWidth, () => {
	navigationVisible.value = false
})

const { trackEvent } = useFathom()
const { environment } = useRuntimeConfig().public

const shopLink = (url) => { // TODO: Das ist obsolet
	return environment === 'production' ? url : '/shop'
}
</script>

<template>
	<nav
		class="navigation"
		:class="{ visible: navigationVisible }"
	>
		<ul
			class="menu"
			@click="toggleNavigation"
		>
			<li
				v-for="({
					title,
					link,
					eventName,
					_uid
				}) in mainNavigation"
				:key="_uid"
			>
				<NuxtLinkLocale
					class="button"
					:to="link.linktype !== 'url' ? resolveLink(link) : shopLink(link.url)"
					:target="link.linktype !== 'url' ? link?.target : null"
					@click="trackEvent(eventName)"
				>
					{{ title }}
				</NuxtLinkLocale>
			</li>
		</ul>
		<MenuButton ref="menuButton" />
	</nav>
</template>

<style scoped>
.navigation {
	user-select: none;

	@media (--mobile-menu) {
		&.visible {
			--translate-y: 100%;
		}
	}
}

.menu {
	transform: translateY(var(--header-y, 0));

	@media (--full-menu) {
		display: flex;
		gap: 1rem;
	}

	@media (--mobile-menu) {
		position: fixed;
		inset: -100% 0 100%;
		transform: translateY(var(--translate-y, var(--header-y, 0)));

		overflow-y: auto;
		display: grid;
		gap: 1rem;
		align-content: start;
		justify-content: center;

		width: 100%;
		min-height: 100%;
		margin: auto;
		padding-block: var(--header-height) var(--space);
		padding-inline: var(--space);

		background-color: var(--color-blue);

		transition-duration: 1s;
		transition-property: background-color, color, transform;
	}

	@media (height > 40rem) and (--mobile-menu) {
		align-content: center;
		padding-block-start: var(--space);
	}
}

li {
	--text-color: var(--foreground-color);

	display: flex;
	list-style: none;

	&:last-child {
		--background-color: var(--color-blue);
		--border-color: var(--color-blue);
		--text-color: var(--color-white);
	}
}

a {

	&:hover,
	&.router-link-active,
	&.router-link-exact-active {
		--background-color: var(--color-paper-white);
		--border-color: var(--color-paper-white);
		--text-color: var(--color-blue);
	}

	&.router-link-exact-active {
		pointer-events: none;
	}

	@media (--mobile-menu) {
		display: flex;
		width: 100%;
		font-size: 2rem;
		text-align: center;
	}
}
</style>
