<script setup>
const props = defineProps({
	error: {
		type: Object,
		required: true
	}
})

const { error } = toRefs(props)

const { t } = useI18n()

const title = computed(() => {
	if (error.value?.statusCode === 404) {
		return '404'
	}

	if (error.value?.statusCode) {
		return `${error.value.statusCode}`
	}

	if (error.value?.code) {
		return error.value.code
	}

	return t('error')
})

const text = computed(() => {
	if (error.value.statusCode === 404) {
		return t('contentDoesNotExist')
	}
	return t('errorText')
})

const message = computed(() => {
	return error.value.message || error.value.statusMessage || text.value
})
</script>

<template>
	<div class="app error">
		<AppHeader />
		<main
			key="error"
			class="grid"
		>
			<h1>{{ title }}</h1>
			<h2>{{ error?.url }}</h2>
			<p>{{ message }}</p>
			<button
				to="/"
				class="button"
				@click="clearError({ redirect: '/' })"
			>
				{{ t('backToHome') }}
			</button>
			<DevOnly>
				<code
					v-if="error.stack"
					v-html="error.stack"
				/>
				<code v-if="error.data">
					{{ error.data }}
				</code>
			</DevOnly>
		</main>
		<AppFooter />
	</div>
</template>

<style scoped>
main {
	gap: 1rem;
	border-block-start: var(--header-height) solid var(--color-blue);

	:last-child {
		margin-block-end: var(--space);
	}
}

h2,
p,
a,
button {
	grid-column: content;
}

h1,
h2,
p {
	color: var(--color-blue);
}

h2 {
	font-family: monospace;
	font-size: var(--font-size-medium);
}

code {
	overflow-x: auto;
	grid-column: frame;

	width: 100%;
	height: auto;
	margin-block: 2rem;
	padding: 1rem;

	color: var(--color-paper-white);
	white-space: break-spaces;

	background-color: var(--color-black);

	& > * {
		white-space: inherit;
	}
}
</style>
