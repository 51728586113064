<script setup>
const { fetchSettings } = useSettingsStore()
await fetchSettings()

const {
	title: siteTitle,
	description
} = toRefs(useSettingsStore())

const i18nHead = useLocaleHead({
	addSeoAttributes: true
})

useHead({
	htmlAttrs: { lang: i18nHead?.value?.htmlAttrs?.lang || 'de' },
	titleTemplate: (titleChunk) => {
		if (titleChunk === siteTitle.value || titleChunk === 'Home') {
			return siteTitle.value
		}

		return titleChunk ? `${titleChunk} — ${siteTitle.value}` : siteTitle.value
	}
})

const { baseUrl } = useRuntimeConfig().public

const image = () => {
	return `${baseUrl}/social.png`
}

useSeoMeta({
	ogType: 'website',
	ogTitle: siteTitle.value,
	twitterTitle: siteTitle.value,
	description: description.value,
	ogDescription: description.value,
	twitterDescription: description.value,
	ogImage: () => image,
	twitterImage: () => image,
	twitterCard: 'summary_large_image'
})

const isResizing = ref(false)

const handleResize = () => {
	isResizing.value = true

	useTimeoutFn(() => {
		isResizing.value = false
	}, 400)
}

useEventListener(window, 'resize', handleResize)

const { finalizePendingLocaleChange } = useI18n()
const onBeforeEnter = async () => {
	await finalizePendingLocaleChange()
}

const footerHeight = useFooterHeight()
watch(footerHeight, () => {
	document.documentElement.style.setProperty('--footer-height', `${footerHeight.value}px`)
})

const currentLayout = useCurrentLayout()
watch(currentLayout, (newLayout) => {
	useHead({ bodyAttrs: { 'data-layout': newLayout } })
}, { immediate: true })
</script>

<template>
	<div class="app">
		<AppHeader />
		<NuxtLayout>
			<NuxtPage
				:page-key="route => route.path"
				:transition="{ onBeforeEnter }"
			/>
		</NuxtLayout>
		<AppFooter />
	</div>
</template>

<style>
@font-face {
	font-family: 'Halifax';
	font-weight: 300;
	font-style: normal;
	font-display: swap;
	src: url('/fonts/Halifax-Light.woff2') format('woff2');
}

@font-face {
	font-family: 'Halifax';
	font-weight: 500;
	font-style: normal;
	font-display: swap;
	src: url('/fonts/Halifax-Medium.woff2') format('woff2');
}

* {
	margin: 0;
	padding: 0;

	&::after,
	&::before {
		margin: 0;
		padding: 0;
	}

	&:not(svg, svg *) {
		transform-origin: 50% 50%;

		box-sizing: border-box;

		text-size-adjust: none;

		background-repeat: no-repeat;
		background-position: 50% 50%;

		transition-timing-function: var(--ease-in-out-sine, ease-in-out);
	}

	&:not(input, textarea) {
		-webkit-tap-highlight-color: hsl(0deg 0% 0% / 0%);
		-webkit-touch-callout: none;
	}

	&:focus-within {
		outline: 0;
	}
}

.is-resizing * {
	transition: none !important;
}

::selection {
	color: var(--color-white);
	background-color: var(--color-blue);
}

:root {
	--color-black: hsl(0deg 0% 11%);
	--color-blue: hsl(232deg 56% 32%);
	--color-white: hsl(0deg 0% 100%);
	--color-paper-white: hsl(240deg 28% 95%);
	--color-gray: hsl(234deg 27% 85%);
	--color-grey: var(--color-gray);
	--color-green: hsl(140deg 38% 42%);
	--color-red: hsl(354deg 62% 36%);
	--color-yellow: hsl(34deg 67% 50%);

	/* Font Settings */
	--serif: serif;
	--sans-serif: 'Halifax', system-ui, sans-serif;
	--monospace: monospace;

	/* Font Size */
	--font-size-min: 15px;
	--font-size: 1.33vw;
	--font-size-max: 19px;

	--font-size-base: 16;

	--font-size-tiny: calc(12rem / var(--font-size-base));
	--font-size-extra-small: calc(14rem / var(--font-size-base));
	--font-size-small: calc(16rem / var(--font-size-base));
	--font-size-medium: calc(20rem / var(--font-size-base));
	--font-size-large: calc(30rem / var(--font-size-base));
	--font-size-extra-large: calc(44rem / var(--font-size-base));
	--font-size-huge: calc(68rem / var(--font-size-base));

	--font-weight-normal: 300;
	--font-weight-bold: 500;

	/* Spacing */
	--content-max-width: 50rem;
	--space: 0.85rem;
	--gap: clamp(1rem, 6vw, 3rem);

	/* Dimensions */
	--header-height: 6rem;
	--header-height-collapsed: 6rem;
	--shop-header-height: 4rem;
	--hero-height: max(15rem, calc(100vh / (9/5)));
	--footer-height: 20rem;
	--padding-inline: var(--space);

	/* Misc */
	--blur-radius: 10px;
	--border-radius: 3rem;
	--border-radius-small: 0.15rem;
	--letter-spacing: 0.14ch;

	/* Shadows */
	--drop-shadow-x: 0;
	--drop-shadow-y: 0.85rem;
	--drop-shadow-blur: 1.25rem;
	--drop-shadow-opacity: 25%;
	--drop-shadow: drop-shadow(var(--drop-shadow-x) var(--drop-shadow-y) var(--drop-shadow-blur) hsl(0deg 0% 0% / var(--drop-shadow-opacity)));

	/* Easings */
	--ease-in: cubic-bezier(0.42, 0, 1, 1);
	--ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
	--ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	--ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
	--ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	--ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
	--ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
	--ease-out: cubic-bezier(0, 0, 0.58, 1);
	--ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
	--ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
	--ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
	--ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
	--ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
	--ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
	--ease-in-out: cubic-bezier(0.42, 0, 0.58, 1);
	--ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
	--ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
	--ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
	--ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
	--ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
	--ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
	--ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

	/* Animations */
	--transition-duration: 320ms;
	--transition-delay: 120ms;
	--transition-timing-function: var(--ease-in-out-sine);
	--transition: var(--transition-duration) var(--transition-timing-function);

	overflow-anchor: none;
	scrollbar-color: var(--color-black) var(--color-paper-white);
	scrollbar-width: thin;
	scrollbar-gutter: stable;

	overflow-x: hidden;

	font-family: var(--sans-serif);
	font-size: clamp(var(--font-size-min), var(--font-size), var(--font-size-max));
	font-weight: var(--font-weight-normal);
	-webkit-font-smoothing: antialiased;
	line-height: 1.5;
	color: var(--color-black, black);

	accent-color: var(--color-blue);
	background-color: var(--color-paper-white);

	@media (prefers-color-scheme: dark) {
		scrollbar-color: var(--color-paper-white) var(--color-black);
	}

	@media (--extra-small) {
		--space: 1.25rem;
	}

	@media (--small) {
		--space: 1.25rem;
		--header-height: 8rem;
	}

	@media (--medium) {
		--space: 2rem;
		--header-height: 10rem;
		--padding-inline: calc(1.5 * var(--space));
	}

	@media (--large) {
		--space: 2.5rem;
		--padding-inline: calc(2 * var(--space));
	}

	@media (--extra-large) {
		--space: 3rem;
		--padding-inline: 12vw;
	}

	@media (--ultra-large) {
		--space: 4rem;
		--padding-inline:
			clamp(10rem,
				calc(25vw - var(--space)),
				40rem);
	}
}

:root,
body {
	scroll-behavior: smooth;
	scroll-padding-top: var(--header-height-collapsed);
	min-height: 100%;
}

.app {
	display: grid;
	grid-auto-flow: row;
	grid-template-areas: 'main' 'footer';
	grid-template-rows: 1fr auto;

	min-height: 100vh;
}

code {
	font-family: monospace;
	white-space: break-spaces;
}

button,
a {
	color: currentColor;
	text-decoration: none;
}

main {
	position: relative;
	z-index: 0;
	grid-area: main;
	background-color: var(--color-paper-white);

	&::before {
		content: '';

		position: absolute;
		z-index: -1;
		inset: 0 0 auto;

		height: var(--hero-height);

		background-color: var(--page-placeholder-color, var(--color-paper-white));
	}

	.underline,
	.follow-up a,
	.content-element a {
		&:not(.button) {
			text-decoration: underline;
			text-decoration-color: var(--text-decoration-color, color-mix(in srgb, var(--color-blue) 50%, var(--color-white) 50%));
			text-decoration-thickness: var(--text-decoration-thickness, 1px);
			text-underline-offset: 0.2em;

			transition-duration: var(--transition-duration);

			&:hover,
			&:focus {
				--text-decoration-color: var(--color-blue);
				--text-decoration-thickness: 2px;
			}
		}
	}
}

h1,
h2,
h3 {
	font-weight: var(--font-weight-bold);
}

h1 {
	grid-column: content;

	max-width: 18ch;
	margin-block-start: calc(var(--space) * 2);
	margin-block-end: var(--space);

	font-size: var(--font-size-extra-large);
	line-height: 1;
	hyphens: auto;
	color: var(--color-blue);
	word-break: keep-all;

	@media (--medium) {
		grid-column: popout;
		font-size: var(--font-size-huge);
	}
}

h2 {
	font-size: var(--font-size-medium);
	line-height: 1.2;
}

h3 {
	font-size: 1rem;
}

b,
strong {
	font-weight: var(--font-weight-bold);
}

.formkit-input[type="submit"],
.formkit-input[type="button"],
.button {
	cursor: pointer;

	transform-origin: 50% 25%;
	transform: scale(var(--scale, 1));

	display: inline-flex;
	justify-self: start;

	margin-block: auto;
	padding: 0.6em 0.9em;

	font-size: inherit;
	font-weight: var(--font-weight-normal);
	line-height: 1;
	color: var(--text-color, var(--color-paper-white));

	background-color: var(--background-color, var(--color-blue));
	backdrop-filter: blur(2px);
	border: 1px solid currentColor;
	border-color: var(--border-color);
	border-radius: 0;

	transition-duration: var(--transition-duration);
	transition-property: transform, background-color, border-color, color;

	&:hover,
	&:focus {
		--border-color: var(--color-blue);
		--text-color: var(--color-blue);
		--background-color: var(--color-paper-white);
	}

	&:active {
		--scale: 0.95;
	}
}

input,
select,
textarea,
button {
	user-select: all;
	font-family: var(--sans-serif);
}

/* select,
input,
button {
	overflow: hidden;

	box-sizing: border-box;
	padding: 1rem 1ch;

	font-size: max(0.8rem, 16px);
	text-overflow: ellipsis;

	appearance: none;
	border: 1px solid var(--color-blue);
	border-radius: 0.5rem;
} */

button {
	user-select: none;
	appearance: none;
	background: none;
	border: 0;
}

img {
	shape-margin: 1rem;

	max-width: 100%;
	height: auto;

	font-style: italic;
	vertical-align: middle;

	background-repeat: no-repeat;
	background-size: cover;
}

.grid {
	--full: minmax(var(--gap), 1fr);
	--content: min(70ch, 100% - var(--gap) * 2);
	--popout: minmax(0, 12rem);
	--frame: minmax(0, 10rem);

	display: grid;
	grid-template-columns: [full-start] var(--full) [frame-start] var(--frame) [popout-start] var(--popout) [content-start] var(--content) [content-end] var(--popout) [popout-end] var(--frame) [frame-end] var(--full) [full-end];
	align-content: baseline;
}

.gradient-overlay {
	&::before {
		pointer-events: none;
		content: '';

		position: absolute;
		z-index: 1;
		inset: 0;

		background-image:
			linear-gradient(to bottom,
				hsl(0deg 0% 0% / var(--gradient-opacity-start, 0%)) var(--gradient-start, 0%),
				hsl(0deg 0% 0% / var(--gradient-opacity-end, 60%)) var(--gradient-end, 100%));
	}
}

.thu img {
	width: 50px;
	height: 50px;
	object-fit: contain;
}
</style>
