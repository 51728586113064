export default defineNuxtRouteMiddleware(async ({ path }) => {
	const { $pinia } = useNuxtApp()

	const shopStore = useShopStore($pinia)
	const { customerIsAuthenticated } = storeToRefs(shopStore)

	if (path.startsWith('/shop/konto/') && !customerIsAuthenticated.value) {
		return await navigateTo('/shop/konto')
	}
})
