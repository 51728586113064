<script setup>
import IconMenu from 'assets/images/menu.svg'

const navigationVisible = useNavigationVisibility()

const isLocked = useScrollLock(document)

const toggleNavigation = () => {
	navigationVisible.value = !navigationVisible.value
	isLocked.value = navigationVisible.value
}

onKeyStroke('Escape', () => {
	toggleNavigation()
})
</script>

<template>
	<button
		class="menu-button"
		:class="{ active: navigationVisible }"
		:aria-label="navigationVisible ? 'Menü schliessen' : 'Menü öffnen'"
		@click="navigationVisible = !navigationVisible"
	>
		<IconMenu />
	</button>
</template>

<style scoped>
button {
	--transition-delay: 0s;
	--icon-size: 2rem;

	cursor: pointer;

	position: relative;
	z-index: 2;
	transform: translateY(var(--header-y, 0));

	display: flex;
	align-items: center;
	justify-content: center;

	color: var(--menu-button-color, currentColor);

	background-color: transparent;
	border: 0;

	& :deep(svg) {
		width: var(--icon-size);
		height: var(--icon-size);
	}

	& :deep(rect) {
		will-change: transform, opacity;

		transform-origin: 50% 50%;
		transform: rotateZ(var(--rotate, 0deg)) rotateY(var(--rotate-y, 0deg)) scale(var(--scale, 1));

		opacity: var(--opacity, 1);

		fill: currentColor;

		transition: var(--transition-duration) var(--transition-delay, 100ms);
	}

	&.active {
		--menu-button-color: var(--color-white);

		:deep(.top),
		:deep(.bottom) {
			--opacity: 0;
			--scale: 0;
		}

		:deep(.top) {
			--rotate-y: -60deg;
		}

		:deep(.bottom) {
			--rotate-y: 60deg;
		}

		:deep(.middle-start),
		:deep(.middle-end) {
			--scale: 1.25;
			--transition-delay: 300ms;
		}

		:deep(.middle-start) {
			--rotate: -45deg;
		}

		:deep(.middle-end) {
			--rotate: 45deg;
		}
	}

	@media (--full-menu) {
		display: none;
	}
}
</style>
