export default defineNuxtPlugin(async (nuxtApp) => {
	try {
		const {
			loginCustomer,
			fetchProductTypes
		} = useShopStore(nuxtApp.$pinia)

		await Promise.all([
			loginCustomer(),
			fetchProductTypes()
		])

		// eslint-disable-next-line no-console
		console.log('Shop-Daten geladen')
	} catch (error) {
		console.error('Fehler beim Laden der Shop-Daten:', error)
	}
})
