export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.hook('app:created', async () => {
		const { fetchSettings } = useSettingsStore(nuxtApp.$pinia)

		try {
			await fetchSettings()
			// eslint-disable-next-line no-console
			console.log('Website-Einstellungen geladen')
		} catch (error) {
			console.error('Fehler beim Laden der Website-Einstellungen:', error)
		}
	})
})
