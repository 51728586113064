<script setup>
const url = new URL('https://codecrush.ch/')

url.searchParams.set('utm_campaign', 'legacy')
url.searchParams.set('utm_medium', 'website')
url.searchParams.set('utm_source', 'moser')
</script>

<template>
	<NuxtLink
		:to="url.toString()"
		class="codecrush-link"
		target="_blank"
		aria-label="Code Crush"
		title="Made by Code Crush"
	>
		<svg
			class="icon"
			viewBox="0 -50 2000 2000"
			preserveAspectRatio="xMidYMid meet"
		>
			<path
				class="letter-c"
				d="M1385.2,522.8c-53.7-129.2-174.9-228.6-365.8-228.6C753,294.2,615.8,487,615.8,723.6 c0,236.6,137.2,429.4,403.5,429.4c190.8,0,312.1-99.4,365.8-228.6h335.9c-65.6,278.3-324,522.8-699.7,522.8 c-461.2,0-743.5-330-743.5-723.6C277.9,330,560.2,0,1021.4,0c375.7,0,634.1,244.5,699.7,522.8H1385.2z"
			/>
			<rect
				class="bar"
				x="303.9"
				y="1723.6"
				width="1391.2"
				height="276.4"
			/>
		</svg>
	</NuxtLink>
</template>

<style scoped>
.codecrush-link {
	--c-color: white;
	--bar-color: white;
	--translate-x: calc(-1rem - 1%);

	cursor: pointer;

	position: absolute;
	inset: auto auto 0 0;
	transform: translate(var(--translate-x, 0), var(--translate-y, 0));

	grid-column: 1;
	grid-row: 2;
	align-self: end;
	justify-self: start;

	margin-block-start: -10rem;

	.icon {
		transform:
			scale(var(--icon-scale, 1)) rotate(var(--icon-rotate, 0deg));

		width: 2rem;
		padding: 1rem;

		transition-timing-function: var(--ease-out-cubic, ease-out);
		transition-duration: 400ms;
		transition-property: transform;

		.letter-c {
			transform-origin: 50% 50%;
			transform:
				translateY(var(--c-translate-y, 100%)) scale(var(--c-scale, 0.8));

			opacity: var(--c-opacity, 0);

			fill: var(--c-color);

			transition-delay: 128ms, 0ms;
			transition-timing-function: var(--ease-in-cubic, ease-in);
			transition-duration: var(--c-transition-duration, 368ms, 432ms);
			transition-property: transform, opacity;
		}

		.bar {
			fill: var(--bar-color);
		}
	}

	&:hover {
		--c-opacity: 1;
		--c-scale: 1;
		--c-translate-y: 0;
		--c-rotate: -10deg;
		--icon-scale: 1.2;
		--icon-rotate: -10deg;
	}

	&:active {
		--c-scale: 0.9;
		--c-translate-y: 5%;
		--c-rotate: 0deg;
		--c-transition-duration: 112ms, 64ms;
	}

	@media (--extra-small) {
		grid-row: 3;
	}

	@media (--small) {
		--translate-y: calc(1rem + 10%);

		position: static;
	}
}
</style>
